<template>
  <div>
    <el-tabs v-model="tab">
      <el-tab-pane label="Laundry Types" name="types">
        <laundry-types />
      </el-tab-pane>
      <el-tab-pane label="Laundry Items" name="items">
        <laundry-items />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Laundry",
  components: {
    LaundryTypes: () =>
      import("@/components/Services/Laundry/LaundryTypes/LaundryTypes"),
    LaundryItems: () =>
      import("@/components/Services/Laundry/LaundryItems/LaundryItems"),
  },
  data() {
    return {
      tab: "types",
    };
  },
};
</script>
